<template>
  <div class="entity-header d-sm-flex flex-md-column flex-lg-row justify-space-between">
    <div class="d-flex flex-row align-start align-md-center mr-0 mr-md-5">
      <icon-button class="mr-6" :to="backRoute">
        <v-icon>mdi-arrow-left</v-icon>
      </icon-button>

      <div v-if="!isLoading" class="d-flex flex-column">
        <h1
          class="entity-header__title text-body-1 text-md-h4 font-weight-bold"
          :class="{ 'text--secondary': isArchived }"
        >
          {{ title }}
        </h1>
        <client-status :status="status" />
      </div>
    </div>

    <div v-if="canUpdate && !isLoading" class="d-flex justify-end mt-4 mt-sm-0 mt-md-4">
      <icon-button v-if="canDisplayMobileEditButton" class="mr-2" color="primary" :to="editRoute">
        <v-icon>mdi-pencil</v-icon>
      </icon-button>
      <v-btn v-else-if="canDisplayEditButton" color="primary" class="mr-4" large :to="editRoute">
        {{ $t('button.edit') }}
        <v-icon right>mdi-pencil</v-icon>
      </v-btn>

      <icon-button v-if="media.isMobile && isArchived" color="secondary" class="primary--text" @click="archive">
        <v-icon>mdi-archive-arrow-up</v-icon>
      </icon-button>
      <v-btn v-else-if="isArchived" color="secondary" large class="primary--text" @click="archive">
        {{ $t('button.unarchive') }}
        <v-icon right>mdi-archive-arrow-up</v-icon>
      </v-btn>

      <icon-button v-else-if="media.isMobile" color="secondary" class="primary--text" @click="archive">
        <v-icon>mdi-archive-arrow-down</v-icon>
      </icon-button>
      <v-btn v-else color="secondary" class="primary--text" large @click="archive">
        {{ $t('button.archive') }}
        <v-icon right>mdi-archive-arrow-down</v-icon>
      </v-btn>

      <icon-button
        v-if="media.isMobile && isInvited && !isShortTenant"
        color="secondary"
        class="primary--text ml-2"
        @click="resentEmail"
      >
        <v-icon>mdi-checkbox-multiple-marked</v-icon>
      </icon-button>
      <v-btn
        v-else-if="isInvited && !isShortTenant"
        class="primary--text ml-4"
        large
        color="secondary"
        @click="resentEmail"
      >
        {{ $t('button.reset_password_email') }}
        <v-icon right>mdi-checkbox-multiple-marked</v-icon>
      </v-btn>

      <icon-button
        v-if="media.isMobile && isUnconfirmed && !isShortTenant"
        color="secondary"
        class="primary--text ml-2"
        @click="sendEmail"
      >
        <v-icon>mdi-checkbox-multiple-marked</v-icon>
      </icon-button>
      <v-btn
        v-else-if="isUnconfirmed && !isShortTenant"
        class="primary--text ml-4"
        large
        color="secondary"
        @click="sendEmail"
      >
        {{ $t('button.send_password_email') }}
        <v-icon right>mdi-checkbox-multiple-marked</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import IconButton from '@/components/IconButton.vue';
import * as entityStatuses from '@/constants/entityStatuses';
import ClientStatus from '@/components/Clients/ClientStatus.vue';

export default {
  name: 'ClientsEntityHeader',

  components: { IconButton, ClientStatus },

  inject: ['media'],

  props: {
    title: { type: String, default: '' },
    backRoute: { type: Object, default: () => ({}) },
    editRoute: { type: Object, default: () => ({}) },
    status: { type: String, default: '' },
    canUpdate: { type: Boolean, default: false },
    isShortTenant: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },

  computed: {
    canDisplayEditButton() {
      return !this.isArchived;
    },

    canDisplayMobileEditButton() {
      return !this.isArchived && this.media.isMobile;
    },
    isArchived() {
      return this.status === entityStatuses.ARCHIVED;
    },
    isUnconfirmed() {
      return this.status === entityStatuses.UNCONFIRMED;
    },
    isInvited() {
      return this.status === entityStatuses.INVITED;
    },
  },

  methods: {
    archive() {
      this.$emit('archive');
    },
    resentEmail() {
      this.$emit('resent-email');
    },
    sendEmail() {
      this.$emit('send-email');
    },
  },
};
</script>
